import { ChangeDetectionStrategy, Component, inject, model, signal } from '@angular/core';
import { SettingsService } from '@features/settings/settings.service';
import { TranslocoDirective } from '@jsverse/transloco';
import { TypographyDirective } from '@shared/directives/typography.directive';
import { ApiService } from '@shared/services/api.service';
import { ToastService } from '@shared/services/toast.service';
import { UserConfiguration } from '@shared/types/user.types';
import { ButtonComponent } from '@shared/ui/button/button.component';

export type PolicyConfig = {
  policy_consent: boolean;
};

@Component({
  selector: 'coach-cookies-popup',
  imports: [TranslocoDirective, ButtonComponent, TypographyDirective],
  templateUrl: './cookies-popup.component.html',
  styleUrl: './cookies-popup.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiesPopupComponent {
  #api = inject(ApiService);
  #settings = inject(SettingsService);
  #toast = inject(ToastService);
  loading = signal(false);

  interactedWith = model(false);

  acceptCookiePolicy(consent: boolean) {
    const policyConsent: PolicyConfig = { policy_consent: consent };
    this.loading.set(true);
    this.interactedWith.set(true);
    this.#api.post<PolicyConfig, UserConfiguration>(`users/configuration`, policyConsent).subscribe({
      next: () => {
        this.#toast.positive('', 'cookies.update.success');
        this.#settings.updatePreference('cookies', 'consent', consent);
        this.#settings.updatePreference('cookies', 'shown', true);
        this.loading.set(false);
      },
      error: () => {
        this.#toast.negative('', 'cookies.update.fail');
        this.loading.set(false);
      },
    });
  }
}
