<div class="menu">
  @if (auth.visibleToCoach()) {
    @if (utility.navigation.isOverlay()) {
      <coach-button (click)="utility.toggleNavigation()" icon="menu" [color]="buttonColor()" />
    }
  } @else {
    <a [routerLink]="['/players', auth.currentUserId(), 'profile']">
      <img class="coach-logo" src="assets/images/coach-logo.svg" alt="Coach logo" />
    </a>
  }
  @if (highlight.showHighlight()) {
    @if (highlight.highlight.isLoading()) {
      <coach-circular-spinner />
    } @else {
      @if (highlight.highlight.value(); as h) {
        <coach-button
          class="highlighted-player"
          [color]="buttonColor()"
          [navigation]="h.navigation"
          [avatar]="h.player?.name"
          [text]="h.player?.name" />
      }
    }
  }
  @if (!auth.isProduction) {
    <div class="hide-on-mobile">
      <coach-role-switcher [color]="buttonColor()" />
    </div>
  }
</div>
@if (utility.isFullScreen()) {
  <img
    class="logo hide-on-mobile"
    src="assets/images/coach-logo-horizontal-primary-no-shield.svg"
    alt="Coach text logo" />
}

<div class="content">
  @if (auth.visibleToCoach()) {
    <coach-active-users-dropdown [dark]="buttonColor() === 'black'" />
  }

  <coach-profile-dropdown [color]="buttonColor()" />
</div>
