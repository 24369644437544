import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, inject } from '@angular/core';
import { AuthService } from '@auth/services/auth.service';
import { environment } from '@env/environment';
import { ToastService } from '@shared/services/toast.service';
import { ErrorResponse } from 'oidc-client-ts';

class CoachErrorHandler extends ErrorHandler {
  #toast = inject(ToastService);
  #auth = inject(AuthService);

  override handleError(error: HttpErrorResponse | ErrorResponse): void {
    if (this.#auth.isAdmin() && !environment.production) {
      // Left here to track down potential causes for errors
      // eslint-disable-next-line no-console
      this.#toast.negative('Unhandled error', error.error ?? error.message ?? 'Unknown error', false);
      throw error;
    }
  }
}

export const provideErrorHandler = () => ({
  provide: ErrorHandler,
  useClass: CoachErrorHandler,
});
