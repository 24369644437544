export type Distance = 'meter' | 'millimeter' | 'yard' | 'yardFeetInches';
export type Speed = 'ms' | 'kmh' | 'mph';
export type Temperature = 'celsius' | 'fahrenheit';

export type Unit = Distance | Speed | Temperature | 'seconds' | 'degrees' | 'days' | 'rpm' | 'dps';
export type UnitType = 'distance' | 'speed' | 'temperature' | 'time' | 'angle' | 'date' | 'rotation';

export type UnitPreference<T> = {
  value: T;
  type: UnitType;
  i18n: string;
  short?: string;
};

export const UnitPreferenceMap: Record<Unit, UnitPreference<Unit>> = {
  meter: {
    value: 'meter',
    type: 'distance',
    i18n: 'settings.distance.meter',
    short: 'm',
  },
  millimeter: {
    value: 'millimeter',
    type: 'distance',
    i18n: 'settings.distance.millimeter',
    short: 'mm',
  },
  yard: {
    value: 'yard',
    type: 'distance',
    i18n: 'settings.distance.yard',
    short: 'yds',
  },
  yardFeetInches: {
    value: 'yardFeetInches',
    type: 'distance',
    i18n: 'settings.distance.yardFeetInches',
    short: 'yd/ft/in',
  },
  ms: {
    value: 'ms',
    type: 'speed',
    i18n: 'settings.speed.metersPerSecond',
    short: 'm/s',
  },
  kmh: {
    value: 'kmh',
    type: 'speed',
    i18n: 'settings.speed.kilometersPerHour',
    short: 'km/h',
  },
  mph: {
    value: 'mph',
    type: 'speed',
    i18n: 'settings.speed.milesPerHour',
    short: 'mph',
  },
  rpm: {
    // revolutions per minute
    value: 'rpm',
    type: 'rotation',
    i18n: 'settings.speed.rpm',
    short: 'rpm',
  },
  dps: {
    // degrees per second
    value: 'dps',
    type: 'rotation',
    i18n: 'settings.speed.dps',
    short: 'dps',
  },
  celsius: {
    value: 'celsius',
    type: 'temperature',
    i18n: 'settings.temperature.celsius',
    short: '°C',
  },
  fahrenheit: {
    value: 'fahrenheit',
    type: 'temperature',
    i18n: 'settings.temperature.fahrenheit',
    short: '°F',
  },
  seconds: {
    value: 'seconds',
    type: 'time',
    i18n: 'settings.time.seconds',
    short: 's',
  },
  degrees: {
    value: 'degrees',
    type: 'angle',
    i18n: 'settings.angle.degrees',
    short: 'deg',
  },
  days: {
    value: 'days',
    type: 'date',
    i18n: 'settings.date.days',
  },
};
