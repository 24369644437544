import { Injectable, computed, inject, signal } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { environment } from '@env/environment';
import { Role } from '@shared/types/generic.types';
import { User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

const AUTH_SETTINGS: UserManagerSettings = {
  authority: environment.auth.issuer,
  client_id: environment.auth.clientId,
  redirect_uri: `${environment.origin}login-callback`,
  silent_redirect_uri: `${environment.origin}silent-callback.html`,
  popup_post_logout_redirect_uri: `${environment.origin}logout-callback.html`,
  monitorSession: true,
  response_type: 'code',
  userStore: new WebStorageStateStore({ store: sessionStorage }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  #router = inject(Router);
  #currentUser = signal<User | null>(null);
  #userManager = new UserManager(AUTH_SETTINGS);
  isProduction = environment.production;

  currentUser = computed(() => this.#currentUser());
  currentUserId = computed(() => this.currentUser()?.profile?.sub);
  isLoggedIn = computed(() => !!this.currentUser());

  roles = computed<Role[]>(() => this.currentUser()?.profile?.['roles'] as Role[]);
  isAdmin = computed(() => this.roles()?.includes('admin'));
  isCoach = computed(() => this.roles()?.includes('coach'));
  isPlayer = computed(() => this.roles()?.includes('player'));

  viewAsRole = signal<Role | null>(!this.isProduction ? (localStorage.getItem('viewAs') as Role | null) : null);
  visibleToCoach = computed(() => this.#visibleTo('coach', this.isAdmin() || this.isCoach()));
  visibleToAdmin = computed(() => this.#visibleTo('admin', this.isAdmin()));

  #visibleTo(role: Role, check: boolean): boolean {
    return this.viewAsRole() ? this.viewAsRole() === role || this.viewAsRole() === 'admin' : check;
  }

  /**
   * The comments in this file are here for getting to the bottom of some auth QOL fixes.
   */

  /* eslint-disable no-console */

  constructor() {
    this.#userManager.events.addUserLoaded((user: User) => {
      this.#currentUser.set(user);
    });
    this.#userManager.events.addUserUnloaded(() => {
      this.#currentUser.set(null);
      this.#router.navigate(['/login']);
    });
    this.#userManager.events.addUserSignedOut(() => {
      this.#userManager.removeUser();
    });

    this.#userManager.events.addAccessTokenExpiring(() => {
      const u = this.#currentUser();
      console.debug(`Access token expiring in ${u?.expires_in}s (${u?.expires_at}).`, u?.expired);
    });

    this.#userManager.events.addSilentRenewError(error => {
      console.debug('Silent renew error.', error, error.cause, error.name, error.message);
    });

    this.#userManager.events.addAccessTokenExpired(() => {
      const u = this.#currentUser();
      console.debug(`Access token expired ${u?.expires_in}s ago (${u?.expires_at}). Trying to manually refresh.`);
      this.signinSilent();
    });
  }

  async signinSilent(): Promise<void | User | null> {
    console.debug('Attempting silent signin');
    return this.#userManager.signinSilent().catch(reason => {
      const nav = this.#router.lastSuccessfulNavigation;
      const url = nav?.extractedUrl.toString();

      switch (reason.error) {
        case 'invalid_grant':
          console.debug('SS: Invalid grant, removing user, logging out', url);
          this.#userManager.removeUser();
          break;
        case 'login_required':
          console.debug('SS: Login required, should we redirect?', url);
          if (url) this.#router.navigate([url]);
          break;
        default:
          console.debug('SS failed:', reason.error, reason);
          console.debug('Logging out, save redirect?', url, nav?.finalUrl?.toString(), nav?.initialUrl.toString());
          this.logOut(url);
          break;
      }
    });
  }

  signinRedirectCallback(): Promise<User> {
    return this.#userManager.signinRedirectCallback();
  }

  async initializeAuth(): Promise<void> {
    const user = await this.#userManager.getUser();
    if (user) {
      this.#currentUser.set(user);
      return;
    }
    if (window.location.pathname !== '/login-callback') {
      await this.signinSilent();
      return;
    }
  }

  async logIn(redirectTo?: string, extraParams?: Record<string, string>): Promise<void> {
    console.debug('Logging in', { redirectTo, extraParams });
    return this.#userManager.signinRedirect({
      state: { redirect: redirectTo, ...extraParams },
      login_hint: localStorage.getItem('login_hint') ?? '',
    });
  }

  async logOut(route = '/login', navigationExtra?: NavigationExtras): Promise<boolean> {
    console.debug('Logging out', { route, navigationExtra });
    try {
      await this.#userManager.signoutSilent();
    } catch (e) {
      console.error('Failed to sign out silently', e);
    }
    return this.#router.navigate([route], navigationExtra);
  }
}
