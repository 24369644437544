import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from '@env/environment';
import { AuthService } from '../services/auth.service';

/**
 * The comments in this file are here for getting to the bottom of some auth QOL fixes.
 */

/* eslint-disable no-console */

export function authInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  const auth = inject(AuthService);
  const token = auth.currentUser()?.access_token;
  const expired = auth.currentUser()?.expired;

  if (req.url.includes(environment.apiUrl)) {
    if (!token || expired) {
      console.debug('Interceptor: Trying to access API with no/expired token, attempting silent signin');
      auth.signinSilent();
    }
    return next(req.clone({ setHeaders: { Authorization: `Bearer ${token}` } }));
  }

  return next(req);
}
