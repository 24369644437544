import { booleanAttribute, ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { Router } from '@angular/router';
import { ModeMap } from '@features/session/session.types';
import { TranslocoDirective } from '@jsverse/transloco';
import { ActiveService } from '@shared/services/active.service';
import { BadgeComponent } from '../badge/badge.component';
import { ButtonColor, ButtonComponent, ButtonVariant } from '../button/button.component';
import { ClickMenuItem } from '../click-menu/click-menu.types';
import { DropdownComponent } from '../dropdown/dropdown.component';

@Component({
  selector: 'coach-active-users-dropdown',
  imports: [DropdownComponent, ButtonComponent, BadgeComponent, TranslocoDirective],
  templateUrl: './active-users-dropdown.component.html',
  styleUrl: './active-users-dropdown.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveUsersDropdownComponent {
  #router = inject(Router);
  dark = input(false, { transform: booleanAttribute });
  actives = inject(ActiveService).actives;

  users = computed<ClickMenuItem[]>(() => {
    const activeUsers = this.actives.value() ?? [];

    if (!this.actives.value()?.length) {
      return [
        {
          id: 'no-active-users',
          type: 'info',
          title: 'coaches.noActivePlayers.title',
          subtitle: 'coaches.noActivePlayers.subtitle',
        },
      ];
    }

    return activeUsers.map((user, i) => ({
      type: 'user',
      id: `${user.user_id}-${i}`,
      title: user.name,
      subtitle: ModeMap[user.game_mode].i18n ?? 'general.unknown',
      action: () => this.#router.navigate([`players/${user.user_id}/session/${user.session_id}`]),
    }));
  });

  count = computed(() => this.users().filter(user => user.type === 'user').length);
  style = computed<{ variant: ButtonVariant; color: ButtonColor }>(() => {
    const variant = this.count() ? 'contained' : 'text';
    const dark = variant === 'contained' ? this.dark() : !this.dark();

    return {
      variant: this.count() ? 'contained' : 'text',
      color: dark ? 'black' : 'white',
    };
  });
}
