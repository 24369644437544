import { effect, inject, Injectable, untracked } from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { PlayerActive } from '@shared/types/user.types';
import { ApiService } from './api.service';
import { PlayerService } from './player.service';
import { PollingService } from './polling.service';

@Injectable({
  providedIn: 'root',
})
export class ActiveService {
  #api = inject(ApiService);
  #polling = inject(PollingService);
  #player = inject(PlayerService);
  #router = inject(Router);

  actives = rxResource({ loader: () => this.#api.get<PlayerActive[]>('active-users') });
  wasActive = false; // used to run a final reload after player turns inactive

  constructor() {
    this.#polling.addPollableResource(this.actives, 'active-users', 5);

    effect(() => {
      const activeIds = this.actives.value()?.map(player => player.user_id);
      const url = this.#router.lastSuccessfulNavigation?.finalUrl?.toString();
      const isLookingAtSessionList = !!activeIds?.filter(id => url?.includes(`/players/${id}/session`)).length;

      untracked(() => {
        if (this.wasActive || isLookingAtSessionList) {
          this.#player.sessions.reload();
          this.wasActive = isLookingAtSessionList;
        }
      });
    });
  }
}
