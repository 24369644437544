@if (auth.isLoggedIn() && auth.visibleToCoach()) {
  <coach-navigation />
}
<main
  [class.with-static-sidebar]="!utility.navigation.isOverlay() && utility.navigation.enabled()"
  [class.backdrop-active]="(utility.navigation.isOverlay() && utility.navigation.toggled()) || utility.showBackdrop()">
  <!-- We only want to handle clicks on the backdrop, it is not focusable -->
  <!-- eslint-disable-next-line -->
  <div class="backdrop" (click)="utility.backdropClicked()"></div>
  @if (auth.isLoggedIn()) {
    <coach-top-bar />
    @if (!(interactedWithCookiesPopup() || settings.settings().cookies.shown)) {
      <coach-cookies-popup [(interactedWith)]="interactedWithCookiesPopup" />
    }
  }
  <router-outlet />
</main>
<ngx-sonner-toaster richColors position="bottom-right" />
