<h1 *transloco="let t; read: 'navigation'" typography="title" variant="2">{{ t('home') }}</h1>

<div class="progress-container" *transloco="let t">
  @for (card of progress(); track card.title) {
    <coach-progress-card
      [title]="card.title"
      [icon]="card.icon"
      [progress]="card.progress"
      [navigation]="card.navigation"
      [loading]="card.loading" />
  }
</div>

<coach-under-construction />
