import { ChangeDetectionStrategy, Component, inject, linkedSignal } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NavigationComponent } from '@core/navigation/navigation.component';
import { TopBarComponent } from '@core/top-bar/top-bar.component';
import { SettingsService } from '@features/settings/settings.service';
import { UtilityService } from '@shared/services/utility.service';
import { NgxSonnerToaster } from 'ngx-sonner';
import { AuthService } from './auth/services/auth.service';
import { CookiesPopupComponent } from './shared/ui/cookies-popup/cookies-popup.component';

@Component({
  selector: 'coach-root',
  imports: [RouterOutlet, NavigationComponent, TopBarComponent, CookiesPopupComponent, NgxSonnerToaster],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  auth = inject(AuthService);
  utility = inject(UtilityService);
  settings = inject(SettingsService);

  interactedWithCookiesPopup = linkedSignal({
    source: this.settings.settings,
    computation: settings => settings.cookies.shown,
  });
}
