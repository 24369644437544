import { inject, Injectable, linkedSignal, ResourceRef, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService } from '@auth/services/auth.service';
import { interval, takeWhile } from 'rxjs';
import { FeatureService } from './feature.service';

type PollableResource = {
  ref: ResourceRef<unknown>;
  name: string;
  cadence: number;
};

@Injectable({
  providedIn: 'root',
})
export class PollingService {
  #feature = inject(FeatureService);
  #auth = inject(AuthService);

  #isPollingEnabled = signal(true);
  isPolling = linkedSignal<boolean>(() => this.#isPollingEnabled());

  pollInterval = 1000;
  pollIterations = 0;

  resources = signal<PollableResource[]>([]);

  interval =
    this.#isPollingEnabled() &&
    interval(this.pollInterval)
      .pipe(
        takeWhile(() => this.#auth.isLoggedIn()),
        takeUntilDestroyed()
      )
      .subscribe(() => {
        if (this.isPolling()) {
          this.pollIterations++;
          this.resources().forEach(resource => {
            if (this.pollIterations % resource.cadence === 0) {
              resource.ref.reload();
            }
          });
        }
      });

  togglePolling(force?: boolean) {
    this.isPolling.update(polling => force ?? !polling);
  }

  addPollableResource(ref: ResourceRef<unknown>, name: string, cadence = 1) {
    if (this.#isPollingEnabled() && this.resources().findIndex(r => r.ref === ref) === -1) {
      this.resources.update(resources => [...resources, { ref, name, cadence }]);
    }
  }

  removePollableResource(ref: ResourceRef<unknown>) {
    if (this.#isPollingEnabled()) {
      this.resources.update(resources => resources.filter(r => r.ref !== ref));
    }
  }
}
